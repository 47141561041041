<template>
  <div class="font-sans">
    <h3 class="text-base md:text-xl font-cocogoose">
      {{ $i18n('additional.faq') }}
    </h3>
    <FaqDropdown :items="items" />
  </div>
</template>

<script>
import FaqDropdown from '~/components/faq/FaqDropdown.vue';

export default {
  components: {
    FaqDropdown,
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    airportName() {
      return this.$store.state.airport.name;
    },
    bestRatedParkings() {
      return this.$store?.getters['daynamicFaq/bestRatedParkings'];
    },
    averagePricePerWeek() {
      return this.$store?.getters['daynamicFaq/averagePricePerWeek'];
    },
    cheapestParkings() {
      return this.$store?.getters['daynamicFaq/cheapestParkings'];
    },
    averagePricePerWeekFAQ() {
      if (
        this.averagePricePerWeek &&
        Number(this.averagePricePerWeek.price) > 0
      ) {
        return [
          {
            title: this.$i18n('additional.faq-how-much-parking', {
              airportName: this.airportName,
            }),
            content: this.$i18n('additional.faq-how-much-parking-answer', {
              airportName: this.airportName,

              pricePerWeek: this.formatPrice(
                this.averagePricePerWeek.price,
                this.averagePricePerWeek.currency ?? 'EUR'
              ),
            }),
          },
        ];
      }
      return [];
    },
    bestRatedParkingsFAQ() {
      if (this.bestRatedParkings && this.bestRatedParkings.length === 3) {
        return [
          {
            title: this.$i18n('additional.faq-what-best-parking', {
              airportName: this.airportName,
            }),
            content: this.$i18n('additional.faq-what-best-parking-answer', {
              airportName: this.airportName,

              firstParkingName: this.bestRatedParkings[0].merchant.name,
              firstParkingScore: Number(
                this.bestRatedParkings[0].review.score
              ).toFixed(1),
              secondParkingName: this.bestRatedParkings[1].merchant.name,
              secondParkingScore: Number(
                this.bestRatedParkings[1].review.score
              ).toFixed(1),
              thirdParkingName: this.bestRatedParkings[2].merchant.name,
              thirdParkingScore: Number(
                this.bestRatedParkings[2].review.score
              ).toFixed(1),
            }),
          },
        ];
      }

      return [];
    },
    cheapestParkingsFAQ() {
      if (this.cheapestParkings && this.cheapestParkings.length === 3) {
        return [
          {
            title: this.$i18n('additional.faq-what-cheapest-parking', {
              airportName: this.airportName,
            }),
            content: this.$i18n('additional.faq-what-cheapest-parking-answer', {
              airportName: this.airportName,
              firstParkingName: this.cheapestParkings[0].merchant.name,
              firstParkingPrice: this.formatPrice(
                this.cheapestParkings[0].price,
                this.cheapestParkings[0].currency ?? 'EUR'
              ),
              secondParkingName: this.cheapestParkings[1].merchant.name,
              secondParkingPrice: this.formatPrice(
                this.cheapestParkings[1].price,
                this.cheapestParkings[1].currency ?? 'EUR'
              ),
              thirdParkingName: this.cheapestParkings[2].merchant.name,
              thirdParkingPrice: this.formatPrice(
                this.cheapestParkings[2].price,
                this.cheapestParkings[2].currency ?? 'EUR'
              ),
            }),
          },
        ];
      }

      return [];
    },
    items() {
      return [
        ...this.averagePricePerWeekFAQ,
        ...this.bestRatedParkingsFAQ,
        ...this.cheapestParkingsFAQ,
        ...this.$store.state.faq,
      ];
    },
  },
  methods: {
    formatPrice(price, currency) {
      return new Intl.NumberFormat(this.language.lang, {
        style: 'currency',
        currency,
      }).format(price);
    },
  },
};
</script>
